import React, { useEffect, useRef, useState } from 'react';
import { ChartBar, LineChart } from 'lucide-react';
import { FaArrowTrendUp } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const heroRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [viewBoxWidth, setViewBoxWidth] = useState(1000);
  const navigate = useNavigate();

  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      setIsMobile(isMobileView);
      // Adjust viewBox width for mobile to ensure candlesticks spread across screen
      setViewBoxWidth(isMobileView ? 500 : 1000);
    };

    // Initial check
    checkMobile();

    // Add resize listener
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const features = [
    {
      icon: <FaArrowTrendUp className="w-8 h-8" />,
      title: "Strategic Investing",
      description: "Long-term wealth building through informed decision-making"
    },
    {
      icon: <ChartBar className="w-8 h-8" />,
      title: "Market Education",
      description: "Simplified concepts and practical learning approaches"
    },
    {
      icon: <LineChart className="w-8 h-8" />,
      title: "Self-Reliance",
      description: "Transform from dependent to confident investor"
    }
  ];

  // Generate candlesticks with specific pattern
  const generateCandlesticks = () => {
    // Define the pattern: up, down, up, more down, last down, then all ups
    const pattern = [
        // Initial "down" trend
        { direction: 'down', basePrice: 335 },
        { direction: 'down', basePrice: 345 },
        { direction: 'down', basePrice: 360 },
        { direction: 'down', basePrice: 370 },
      
        // Minor fluctuation "up"
        { direction: 'up', basePrice: 342 },
        { direction: 'up', basePrice: 320 },
        { direction: 'up', basePrice: 300 },
        { direction: 'up', basePrice: 260 },

        
        // Another "down" trend
        { direction: 'down', basePrice: 280 },
        { direction: 'down', basePrice: 340 },
        { direction: 'down', basePrice: 360 },
        { direction: 'down', basePrice: 370 },
        { direction: 'down', basePrice: 375 },
        { direction: 'down', basePrice: 400 },
        { direction: 'down', basePrice: 405 },
        { direction: 'down', basePrice: 390},


      
        // Long "up" trend with fluctuations
        { direction: 'up', basePrice: 380 },
        { direction: 'up', basePrice: 370 },
        { direction: 'up', basePrice: 350 },
        { direction: 'up', basePrice: 330 },
        { direction: 'up', basePrice: 320 },
        
        // Small "down" fluctuations
        { direction: 'down', basePrice: 346 },
        { direction: 'down', basePrice: 344 },
      
        // Mid-range "up" trend
        { direction: 'up', basePrice: 340 },
        { direction: 'up', basePrice: 320 },
        { direction: 'up', basePrice: 290 },
        { direction: 'up', basePrice: 250 },
        { direction: 'up', basePrice: 245 },
        { direction: 'up', basePrice: 230 },
        { direction: 'up', basePrice: 210 },
        { direction: 'down', basePrice: 220 },
        { direction: 'up', basePrice: 210 },
        { direction: 'up', basePrice: 210 },
      ];   

    return pattern.map((p, i) => {
      const bodyHeight = 20 + Math.random() * 20;
      const isGreen = p.direction === 'up';
      return {
        id: i,
        basePrice: p.basePrice,
        height: bodyHeight,
        isGreen,
        wickTop: isGreen ? (10 + Math.random() * 10) : (5 + Math.random() * 8),
        wickBottom: isGreen ? (5 + Math.random() * 8) : (10 + Math.random() * 10),
        delay: i * 0.1
      };
    });
  };

  return (
    <div className="relative min-h-screen bg-white overflow-hidden mt-20" ref={heroRef}>
      {/* Animated candlestick background */}
      <div className="absolute inset-0 z-0 opacity-10">
        <div className="relative h-full w-full">
          <svg 
            className="w-full h-full" 
            viewBox={`0 0 ${viewBoxWidth} 800`} 
            preserveAspectRatio="xMidYMid slice"
          >
            {/* Candlesticks */}
            {generateCandlesticks().map((candle, index) => {
              // Calculate spacing based on total width and number of candlesticks
              const totalCandlesticks = generateCandlesticks().length;
              const spacing = viewBoxWidth / (totalCandlesticks + 1);
              const x = spacing + (index * spacing);
              const y = candle.basePrice;
              const candleWidth = isMobile ? spacing * 0.4 : 20; // Proportional width on mobile
              
              return (
                <g 
                  key={candle.id} 
                  className="animate-fade-slide-in" 
                  style={{
                    animation: `candleAppear 0.5s ${candle.delay}s forwards`,
                    opacity: 0,
                    transform: `translateY(${20}px)`
                  }}
                >
                  {/* Wick */}
                  <line
                    x1={x}
                    y1={y - candle.wickTop}
                    x2={x}
                    y2={y + candle.height + candle.wickBottom}
                    stroke={candle.isGreen ? '#22C55E' : '#EF4444'}
                    strokeWidth={isMobile ? "1" : "2"}
                  />
                  {/* Body */}
                  <rect
                    x={x - candleWidth/2}
                    y={y}
                    width={candleWidth}
                    height={candle.height}
                    fill={candle.isGreen ? '#22C55E' : '#EF4444'}
                    opacity="0.8"
                  />
                </g>
              );
            })}
          </svg>
        </div>
      </div>

      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-24" id="hero-content">
        <div className="text-center">
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-gray-900 mb-6">
            <span className="inline-block">Bridging the</span>{' '}
            <span className="text-[#CE0058] inline-block">Financial Divide</span>
          </h1>
          <h2 className="text-2xl sm:text-3xl font-medium text-gray-600 mb-8">
            Turning Overwhelm into Empowerment
          </h2>
          <p className="max-w-3xl mx-auto text-gray-600 mb-12 leading-relaxed">
            Transform from a market participant to an informed contributor in India's financial growth story. 
            We demystify investing through a blend of fundamentals, technical analysis, and common sense.
          </p>
          
          <div className="flex flex-wrap justify-center gap-4">
            <button className="px-8 py-3 bg-[#CE0058] text-white rounded-full font-medium hover:shadow-lg hover:bg-[#CE0058]/90 transform hover:-translate-y-0.5 transition-all"
            onClick={() => { 
              console.log("in clco")
              navigate("/contact") }}>
             Contact Us
            </button>
           
          </div>

          <p className="mt-12 text-gray-600 italic">
            "Building Wealth, Wisdom, and Self-Reliance"
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mt-20">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="bg-white/80 backdrop-blur-sm rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow group"
            >
              <div className="w-12 h-12 bg-[#CE0058]/10 rounded-lg flex items-center justify-center mb-4 group-hover:bg-[#CE0058]/20 transition-colors">
                <div className="text-[#CE0058]">{feature.icon}</div>
              </div>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        @keyframes candleAppear {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @media (max-width: 768px) {
          @keyframes candleAppear {
            0% {
              opacity: 0;
              transform: translateY(10px);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      `}</style>
    </div>
  );
};

export default HeroSection;