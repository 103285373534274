import React from 'react';

const PathSection = () => {
  const steps = [
    {
      title: "Step 1. Empowerment Through Education",
      content: "Like a seed nurtured to become a sturdy tree, we guide investors to grow from beginners to self-reliant strategists, fostering knowledge that stands the test of time. We simplify complex concepts into practical lessons, enabling everyone to understand the roots of wealth creation."
    },
    {
      title: "Step 2. Simplicity & Transparency",
      content: "Clear water reflects the truth without distortion. We believe in complete transparency in our advisory, ensuring clients understand every recommendation. No hidden agendas, only clear, concise, and honest guidance that builds trust and integrity."
    },
    {
      title: "Step 3. Practical Application of Experience",
      content: "Just as a master craftsman knows the feel of every tool, we bring over 26 years of experience, teaching practical, real-world strategies. We don't just teach theory; we share the wisdom of applied commonsense, honed through decades of market insights."
    },
    {
      title: "Step 4. Tailored Investment Roadmaps",
      content: "Like a compass guiding travellers to their destination, we create personalized investment plans that evolve with the client's journey, mapping their path from novice to seasoned investor. Every roadmap is unique, just like the aspirations and dreams of each individual."
    },
    {
      title: "Step 5. Holistic Integration",
      content: "A balanced meal nourishes the body, mind, and soul. Similarly, we integrate the essentials fundamentals, technicals, and common sense to create a balanced approach that ensures financial health, longevity, and growth, empowering investors to thrive in any market environment."
    },
    {
      title: "Step 6. Cultivating a Community",
      content: "Just as a family supports each member, our community nurtures collaboration, learning, and shared growth. We're building a movement, not just a service where investors feel a sense of belonging, united in the shared mission of financial independence and national prosperity."
    },
    {
      title: "Step 7. Contributing to India's Growth",
      content: "Drops of water coming together to form a river, our small contributions aim to create a vast, unstoppable current. We see ourselves as humble participants in India's financial evolution, helping investors grow, and in turn, fuelling the nation's rise as a global economic power."
    }
  ];

  return (
    <div className="bg-gradient-to-b from-white to-gray-50 py-12 sm:py-20">
      {/* Header Section */}
      <div className="text-center max-w-4xl mx-auto px-4 mb-12 sm:mb-20 opacity-0 animate-fade-in">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 mb-6">
          The Path
        </h2>
        <p className="text-gray-600 leading-relaxed text-base sm:text-lg">
          We are "Building Wealth, Wisdom, and Self-Reliance by Empowering Investors to Learn, Unlearn, and Relearn for India's Financial Future". 
          The seven steps at REequitiz mirrors the 'SAPTAPADI', symbolizing sacred vows of unity and commitment. 
          Each step is a promise, guiding investors from knowledge to empowerment. 
          Like 'Saptapadi', it embodies trust, growth, and a lifelong bond, transforming investing from an event into a lasting journey toward financial independence and national prosperity.
        </p>
      </div>

      {/* Timeline Section */}
      <div className="relative max-w-7xl mx-auto px-4">
        {/* Desktop Timeline Line */}
        <div className="absolute left-1/2 top-0 bottom-0 w-0.5 bg-[#CE0058] opacity-20 hidden md:block">
          <div className="absolute inset-0 bg-gradient-to-b from-[#CE0058]/20 to-transparent" style={{ backgroundSize: '1px 20px' }} />
        </div>

        {/* Mobile Timeline Line */}
        <div className="absolute left-4 sm:left-8 md:left-1/2 top-0 bottom-0 w-0.5 bg-[#CE0058] opacity-20">
          <div className="absolute inset-0 bg-gradient-to-b from-[#CE0058]/20 to-transparent" style={{ backgroundSize: '1px 20px' }} />
        </div>

        {/* Step Cards */}
        {steps.map((step, index) => (
          <div 
            key={index}
            className={`relative flex mb-8 sm:mb-12 md:mb-20 ${
              index % 2 === 0 ? 'md:justify-start' : 'md:justify-end'
            } opacity-0 animate-slide-in`}
            style={{
              animationDelay: `${index * 0.2}s`,
            }}
          >
            {/* Timeline dot */}
            <div className={`absolute left-4 sm:left-8 md:left-1/2 top-8 w-3 h-3 rounded-full bg-[#CE0058] transform -translate-x-1/2 animate-pulse z-10`} />
            
            {/* Card */}
            <div 
              className={`relative w-full md:w-5/12 ml-8 sm:ml-12 md:ml-0 group ${
                index % 2 === 0 ? 'md:mr-auto' : 'md:ml-auto'
              }`}
            >
              <div className="bg-white rounded-xl p-5 sm:p-6 md:p-8 shadow-lg transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-1 border border-gray-100 hover:border-[#CE0058]/20">
                {/* Step Number - Inline for mobile */}
                <div className="text-[#CE0058] font-semibold text-sm sm:text-base mb-2">
                  Step {index + 1}
                </div>
                
                <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-900 mb-3 group-hover:text-[#CE0058] transition-colors">
                  {step.title.replace(`Step ${index + 1}.`, '').trim()}
                </h3>
                
                <p className="text-gray-600 leading-relaxed text-sm sm:text-base">
                  {step.content}
                </p>
                
                {/* Decorative Elements - Hidden on mobile */}
                <div className="absolute top-0 right-0 w-16 h-16 bg-[#CE0058]/5 rounded-tr-xl rounded-bl-2xl -z-10 transition-all duration-300 group-hover:bg-[#CE0058]/10 hidden md:block" />
                <div className="absolute bottom-0 left-0 w-12 h-12 bg-[#CE0058]/5 rounded-bl-xl rounded-tr-2xl -z-10 transition-all duration-300 group-hover:bg-[#CE0058]/10 hidden md:block" />
              </div>
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }

        @keyframes slideIn {
          from { 
            opacity: 0;
            transform: translateX(${props => props.isEven ? '-50px' : '50px'});
          }
          to { 
            opacity: 1;
            transform: translateX(0);
          }
        }

        .animate-fade-in {
          animation: fadeIn 1s forwards;
        }

        .animate-slide-in {
          animation: slideIn 1s forwards;
        }

        @media (max-width: 768px) {
          .animate-slide-in {
            animation: fadeIn 1s forwards;
          }
        }
      `}</style>
    </div>
  );
};

export default PathSection;