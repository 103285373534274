import React, { useEffect, useRef } from 'react';
import { FaUserAlt, FaQuestionCircle, FaBalanceScale, FaRegLightbulb, FaMoneyBillAlt } from 'react-icons/fa';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { BiTargetLock } from "react-icons/bi";
import { TbGraph } from "react-icons/tb";

const PhilosophySection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const ctx = gsap.context(() => {
      gsap.fromTo(
        '.bg-pattern',
        { scale: 0, opacity: 0 },
        { scale: 1, opacity: 0.05, duration: 1.5, stagger: 0.5, ease: 'power2.out', scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top 80%',
          end: 'top 20%',
          scrub: true
        }}
      );

      gsap.utils.toArray('.philosophy-card').forEach((card, i) => {
        gsap.fromTo(
          card,
          { y: 50, opacity: 0 },
          { y: 0, opacity: 1, duration: 1, delay: i * 0.2, ease: 'power2.out', scrollTrigger: {
            trigger: card,
            start: 'top 80%',
            end: 'top 20%',
            scrub: true
          }}
        );
      });
    }, sectionRef);

    return () => ctx.revert();
  }, []);

  const philosophies = [
    { icon: <FaUserAlt />, title: "Self-Reliance", description: "Investing isn't about following others; it's about making your own informed choices. We guide you to understand the 'why' behind each decision, building confidence to navigate the market independently." },
    { icon: <FaQuestionCircle />, title: "Questioning the Norms", description: "Trends come and go, but true wealth is built on what matters to you. We help you question the norms and focus on creating a portfolio that reflects your own goals and beliefs." },
    { icon: <BiTargetLock />, title: "Purpose-Driven Investing", description: "Purpose gives investing meaning. Whether it's financial freedom, building wealth for the next generation, or supporting industries you believe in, your portfolio should reflect your life's vision." },
    { icon: <FaBalanceScale />, title: "Balance and Adaptability", description: "Markets change, and so should your strategies. We believe in a balance of structure and flexibility." },
    { icon: <TbGraph />, title: "Risk as Growth", description: "Taking risks can be a stepping stone to growth, if approached wisely. We see calculated risks as part of the journey." },
    { icon: <FaRegLightbulb />, title: "Mindfulness and Presence", description: "Mindfulness in investing means staying grounded and making informed choices, even during market swings." },
    { icon: <FaMoneyBillAlt />, title: "Financial Independence", description: "True freedom is the ability to make choices without financial worry. We focus on building wealth that supports your life's dreams." }
  ];

  return (
    <section className="relative bg-white py-12 sm:py-16 lg:py-20 overflow-hidden w-full" ref={sectionRef}>
      {/* Decorative circles - adjusted positioning and removed right circle */}
      <div className="absolute inset-0 z-0">
        <div className="absolute w-40 sm:w-56 h-40 sm:h-56 -top-8 -left-8 sm:-top-16 sm:-left-16 bg-[#CE0058]/10 rounded-full bg-pattern animate-pulse"></div>
        <div className="absolute inset-0 opacity-[0.02] bg-pattern"></div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-8 sm:mb-12 text-center">
          The Philosophy
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {philosophies.map((philosophy, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-xl p-4 sm:p-6 transition-all duration-300 hover:shadow-lg philosophy-card transform hover:-translate-y-2"
            >
              <div className="flex items-center mb-3 sm:mb-4 space-x-3 sm:space-x-4">
                <div className="text-[#CE0058] text-3xl sm:text-4xl md:text-5xl flex-shrink-0">
                  {philosophy.icon}
                </div>
                <h3 className="text-base sm:text-lg md:text-xl font-semibold text-gray-800">
                  {philosophy.title}
                </h3>
              </div>
              <p className="text-gray-600 text-sm md:text-base leading-relaxed">
                {philosophy.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PhilosophySection;