// import React, { useEffect, useRef } from 'react';

// const DisclaimerPage = () => {
//   const bgDotsRef = useRef(null);
  
//   useEffect(() => {
//     const handleMouseMove = (e) => {
//       if (bgDotsRef.current) {
//         const x = e.clientX / window.innerWidth;
//         const y = e.clientY / window.innerHeight;
//         bgDotsRef.current.style.transform = `translate(${x * 20}px, ${y * 20}px)`;
//       }
//     };

//     window.addEventListener('mousemove', handleMouseMove);
//     return () => window.removeEventListener('mousemove', handleMouseMove);
//   }, []);

//   return (
//     <div className="min-h-screen flex flex-col mt-20">

//       {/* Main Content */}
//       <main className="flex-grow relative bg-white overflow-hidden">
//         {/* Animated Background Dots */}
//         <div
//           ref={bgDotsRef}
//           className="absolute inset-0 pointer-events-none opacity-10"
//           style={{
//             backgroundImage: 'radial-gradient(#BD1B66 1px, transparent 1px)',
//             backgroundSize: '50px 50px'
//           }}
//         />

//         <div className="container mx-auto px-4 py-12 relative z-10">
//           <div className="max-w-4xl mx-auto bg-white/80 backdrop-blur-sm rounded-lg shadow-xl p-8 border border-gray-100">
//             <h2 className="text-3xl font-bold text-[#BD1B66] mb-8">Disclaimer</h2>
            
//             <div className="space-y-6 text-gray-700">
//               <p className="leading-relaxed">
//                 "REequitiz" shares insights and views on stock market investments, empowering investors with knowledge to navigate equity investing.
//               </p>

//               <div className="border-l-4 border-[#BD1B66] pl-4 py-2 bg-pink-50">
//                 <p className="italic">
//                   Information is shared based on data from publicly available sources, which we believe is extremely reliable. However, we and associated persons do not guarantee accuracy or completeness of the data.
//                 </p>
//               </div>

//               <div className="bg-gray-50 p-4 rounded-lg">
//                 <h3 className="text-lg font-semibold text-[#BD1B66] mb-2">Important Notes:</h3>
//                 <ul className="list-disc list-inside space-y-2">
//                   <li>Educational purposes only - not financial advice</li>
//                   <li>No offer or solicitation for sale/purchase of securities</li>
//                   <li>Investments involve risk and are not guaranteed</li>
//                   <li>Consult qualified financial advisor before implementing strategies</li>
//                   <li>We accept no liability for use of shared information</li>
//                 </ul>
//               </div>

//               <p className="font-medium">
//                 Investors are solely responsible for their own personal or financial goals, considering all known/unknown facts of risk depending on their decision proficiency.
//               </p>

//               <div className="text-sm bg-pink-50 p-4 rounded-lg">
//                 <p className="font-semibold text-[#BD1B66]">Legal Notice:</p>
//                 <p>We will have no legal responsibility or liability towards any loss of health, wealth, or damages.</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </main>
//     </div>
//   );
// };

// export default DisclaimerPage;

import React, { useEffect, useRef } from 'react';


const DisclaimerPage = () => {
  return (
    <div className="min-h-screen flex flex-col mt-20">
      <main className="flex-grow relative bg-white overflow-hidden">

        {/* Content */}
        <div className="container mx-auto px-4 py-12 relative z-10">
          <div className="max-w-4xl mx-auto backdrop-blur-sm rounded-xl shadow-2xl p-8 border border-white/20 bg-white/90">
            <h2 className="text-4xl font-bold text-[#BD1B66] mb-8">
              Disclaimer
            </h2>
            
            <div className="space-y-8 text-gray-700">
              <p className="leading-relaxed text-lg">
                "REequitiz" shares insights and views on stock market investments, empowering investors with knowledge to navigate equity investing.
              </p>

              <div className="border-l-4 border-[#BD1B66] pl-6 py-4 bg-pink-50/70 rounded-r-lg">
                <p className="italic text-gray-800">
                  Information is shared based on data from publicly available sources, which we believe is extremely reliable. However, we and associated persons do not guarantee accuracy or completeness of the data.
                </p>
              </div>

              <div className="bg-gray-50/80 p-6 rounded-xl shadow-inner">
                <h3 className="text-xl font-semibold text-[#BD1B66] mb-4">Important Notes</h3>
                <ul className="list-none space-y-3">
                  {[
                    'Educational purposes only - not financial advice',
                    'No offer or solicitation for sale/purchase of securities',
                    'Investments involve risk and are not guaranteed',
                    'Consult qualified financial advisor before implementing strategies',
                    'We accept no liability for use of shared information'
                  ].map((item, index) => (
                    <li key={index} className="flex items-start space-x-3">
                      <span className="text-[#BD1B66] mt-1">•</span>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <p className="font-medium text-lg text-[#BD1B66]">
                Investors are solely responsible for their own personal or financial goals, considering all known/unknown facts of risk depending on their decision proficiency.
              </p>

              <div className="text-sm bg-pink-50/70 p-6 rounded-xl border border-pink-100">
                <p className="font-semibold text-[#BD1B66] text-lg mb-2">Legal Notice:</p>
                <p className="text-gray-700">We will have no legal responsibility or liability towards any loss of health, wealth, or damages.</p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <style jsx global>{`
        @keyframes draw {
          to {
            stroke-dashoffset: 0;
          }
        }

        @keyframes fade-in {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes slide-left {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(-100%);
          }
        }

        .animate-draw {
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: draw 2s ease-out forwards;
        }

        .animate-fade-in {
          opacity: 0;
          animation: fade-in 0.5s ease-out forwards;
        }

        .animate-slide-left {
          animation: slide-left 20s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default DisclaimerPage;