import React from 'react';
import { Mail, Phone, MapPin, Send, User, MessageSquare } from 'lucide-react';

const ContactUs = () => {
  return (
    <div className="min-h-screen mt-20 bg-gradient-to-b from-final/10 to-white">
      {/* Header Section */}
      <div className="bg-final text-white py-16">
        <div className="container mx-auto text-center px-4">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">Contact REequitiz</h1>
          <p className="text-xl md:text-2xl opacity-90">Building Wealth, Wisdom, and Self-Reliance Together</p>
        </div>
      </div>

      {/* Main Content */}
      <div className="container max-w-7xl mx-auto px-4 py-16 -mt-20">
        <div className="grid md:grid-cols-2 gap-8">
          {/* Contact Form Section */}
          <div className="bg-white w-full rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300 overflow-hidden">
            <div className="p-4">
              <div className="mb-4">
                <h2 className="text-2xl font-bold text-final">Send Us a Message</h2>
              </div>
              
              <form className="space-y-4">
                <div className="relative">
                  <User className="absolute left-3 top-11 text-gray-400" size={20} />
                  <label className="block text-sm font-medium text-gray-700 mb-2">Your Name</label>
                  <input
                    type="text"
                    className="pl-10 w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-final focus:border-transparent"
                    placeholder="Enter your full name"
                  />
                </div>

                <div className="relative">
                  <Mail className="absolute left-3 top-11 text-gray-400" size={20} />
                  <label className="block text-sm font-medium text-gray-700 mb-2">Your Email</label>
                  <input
                    type="email"
                    className="pl-10 w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-final focus:border-transparent"
                    placeholder="Enter your email address"
                  />
                </div>

                <div className="relative">
                  <MessageSquare className="absolute left-3 top-11 text-gray-400" size={20} />
                  <label className="block text-sm font-medium text-gray-700 mb-2">Your Message</label>
                  <textarea
                    className="pl-10 w-full p-3 border border-gray-200 rounded-lg h-32 focus:ring-2 focus:ring-final focus:border-transparent"
                    placeholder="Type your message here"
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="w-full bg-final hover:bg-[#CE0058] text-white p-4 rounded-lg font-semibold transition-colors duration-300 flex items-center justify-center gap-2"
                >
                  <Send size={20} />
                  Send Message
                </button>
              </form>
            </div>
          </div>

          {/* Contact Information Section */}
          <div className="space-y-4">
            <div className="bg-white rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300 overflow-hidden">
              <div className="p-6">
                <div className="mb-6">
                  <h2 className="text-2xl font-bold text-final">Contact Information</h2>
                </div>
                
                <div className="space-y-6">
                  <div className="flex items-center gap-4">
                    <div className="bg-final/10 p-3 rounded-full">
                      <Mail className="text-final" size={24} />
                    </div>
                    <div>
                      <h3 className="font-semibold text-black">Email</h3>
                      <p className="text-gray-600">support@reequitiz.in</p>
                    </div>
                  </div>

                  <div className="flex items-center gap-4">
                    <div className="bg-final/10 p-3 rounded-full">
                      <Phone className="text-final" size={24} />
                    </div>
                    <div>
                      <h3 className="font-semibold text-black">Phone</h3>
                      <p className="text-gray-600">+91 88664 12448</p>
                      </div>
                  </div>

                  <div className="flex items-center gap-4">
                    <div className="bg-final/10 p-3 rounded-full">
                      <MapPin className="text-final" size={24} />
                    </div>
                    <div>
                      <h3 className="font-semibold text-black">Address</h3>
                      <p className="text-gray-600">B/410, Ganesh Plaza, Nr. Navrangpura Post Office, Navrangpura, Ahmedabad, Gujarat, India - 380 009</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Map Section */}
            <div className="bg-white rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300 overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.684982717429!2d72.55826618629733!3d23.035335962144465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84f461610533%3A0xa56a93bc9468d0!2sGanesh%20Plaza%2C%20Navrangpura%20Rd%2C%20Near%20Navrangpura%20Post%20Office%2C%20Shrimali%20Society%2C%20Navrangpura%2C%20Ahmedabad%2C%20Gujarat%20380009!5e0!3m2!1sen!2sin!4v1730131237754!5m2!1sen!2sin"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
