import React, { useState } from 'react';
import { ArrowRight, Calendar, User, X } from 'lucide-react';
import Blog1 from "../assets/blog1.webp"
import Blog2 from "../assets/blog2.webp"
import Blog3 from "../assets/blog3.webp"


const BlogListing = () => {
  const [selectedBlog, setSelectedBlog] = useState(null);

  const blogs = [
    {
      title: "The Power of Knowledge: Why Every Investor Should Learn Before They Earn",
      image: Blog1,
      excerpt: "Investing has become increasingly accessible, and with it, a world of opportunity has opened up for individuals looking to grow their wealth. But what does it mean to invest wisely? At REequitiz, we believe the journey to smart investing begins with a foundation in knowledge...",
      content: `Investing has become increasingly accessible, and with it, a world of opportunity has opened up for individuals looking to grow their wealth. But what does it mean to invest wisely? At REequitiz, we believe the journey to smart investing begins with a foundation in knowledge. Before entering the market, an investor needs to understand the basic principles that define it.

Learning the fundamentals of equity, the factors that influence stock prices, and the strategies that have stood the test of time are vital first steps. But understanding is only part of the process. Knowledge builds confidence, and with it, the ability to make informed decisions—decisions that reduce the urge to react impulsively to market shifts.

Our goal is to empower you to move beyond the surface level, helping you approach the market with a plan instead of just a hope. Through structured learning in fundamental and technical analysis, combined with practical wisdom, we help demystify the complexities of the market. When you invest in knowledge first, you create a pathway to sustainable, long-term growth.
     Key Takeaway: 
     Before focusing on potential gains, focus on understanding the market. Knowledge is the foundation upon which successful investing is built.`,
      date: "October 29, 2024",
      author: "Team REequitiz",
      category: "Investment Education",
      readTime: "5 min read"
    },
    {
      title: "Breaking Free from Dependency: The Case for Self-Reliant Investing",
      image: Blog2,
      excerpt: "In India, investing has traditionally been seen as an activity reserved for experts, advisors, or family heads, leaving the average investor dependent on outside guidance. This approach may provide initial support, but it often creates a cycle of dependency...",
      content: `In India, investing has traditionally been seen as an activity reserved for experts, advisors, or family heads, leaving the average investor dependent on outside guidance. This approach may provide initial support, but it often creates a cycle of dependency, where investors make decisions based on tips rather than knowledge, speculation rather than strategy.

At REequitiz, we advocate for a self-reliant approach, where education is the cornerstone of your investment journey. With the right resources, you can become an independent investor, making confident decisions that reflect your own goals and values. Our philosophy is simple: knowledge shared is knowledge gained.

Financial independence means more than just having money; it means understanding how to manage it wisely. This understanding frees you from the constant need for guidance, giving you control over your financial future. Our goal is to walk alongside you in this journey, giving you the tools, resources, and insights to move from passive investing to proactive growth.

Key Takeaway: 
Self-reliance in investing not only builds confidence but fosters a sense of ownership. REequitiz is here to help you transition from dependency to independence.`,
      date: "October 28, 2024",
      author: "Market Research Team",
      category: "Market Analysis",
      readTime: "6 min read"
    },
    {
      title: " Investing for the Future: How Long-Term Planning Shapes Financial Success",
      image: Blog3,
      excerpt: "When we think of investing, it’s often tempting to focus on short-term gains. Many first-time investors are driven by market trends, immediate news, or quick tips that promise fast returns. However, true financial growth is seldom achieved overnight...",
      content: `When we think of investing, it’s often tempting to focus on short-term gains. Many first-time investors are driven by market trends, immediate news, or quick tips that promise fast returns. However, true financial growth is seldom achieved overnight. At REequitiz, we focus on a strategy that promotes long-term, strategic investing to create lasting wealth.

Long-term investing requires patience, consistency, and an understanding of market cycles. When you invest with a long-term perspective, you’re not swayed by short-term volatility. Instead, you see downturns as opportunities to build and strengthen your portfolio. Our goal is to guide you toward a mindset that values growth over time, aligned with your financial goals and life ambitions.

The journey to wealth isn’t a sprint; it’s a marathon. Through our resources and guidance, we help you build a plan, anticipate challenges, and make decisions that align with your vision for the future. With REequitiz, you’re not just investing in the market; you’re investing in your future self.

Key Takeaway: 
Long-term investing is a journey of steady growth and informed choices. Embrace the future with a plan, and let REequitiz help you build it step by step.`,
      date: "October 27, 2024",
      author: "Investment Strategy Team",
      category: "Wealth Building",
      readTime: "7 min read"
    }
  ];

  const BlogModal = ({ blog, onClose }) => {
    if (!blog) return null;

    return (
      <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
          <div className="sticky top-0 bg-white z-10 p-6 border-b flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <span className="bg-final text-white px-4 py-1 rounded-full text-sm font-medium">
                {blog.category}
              </span>
            </div>
            <button 
              onClick={onClose}
              className="text-black/50 hover:text-black transition-colors duration-300"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="p-6">
            <h2 className="text-3xl font-bold text-black mb-4">
              {blog.title}
            </h2>

            <div className="flex items-center space-x-4 text-sm text-black/60 mb-6">
              <div className="flex items-center">
                <Calendar className="w-4 h-4 mr-1" />
                {blog.date}
              </div>
              <div className="flex items-center">
                <User className="w-4 h-4 mr-1" />
                {blog.author}
              </div>
              <span>{blog.readTime}</span>
            </div>

            <div className="mb-8">
              <img
                src={blog.image}
                alt="Blog header"
                className="w-full h-100 object-cover rounded-xl"
              />
            </div>

            <div className="prose max-w-none">
              {blog.content.split('\n').map((paragraph, index) => (
                <p key={index} className="text-black/70 mb-4">
                  {paragraph.trim()}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-white py-16 mt-20">
      {/* Header Section */}
      <div className="container mx-auto px-4 mb-12">
        <div className="text-center max-w-3xl mx-auto">
          <h1 className="text-4xl md:text-5xl font-bold text-[#BD1B66] mb-4">
            Investment Insights
          </h1>
          <p className="text-lg text-black/70">
            Discover valuable insights, market analysis, and investment strategies from our expert team
          </p>
        </div>
      </div>

      {/* Blog Grid */}
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 gap-12 max-w-5xl mx-auto">
          {blogs.map((blog, index) => (
            <article 
              key={index} 
              className="bg-white rounded-2xl shadow-lg overflow-hidden transform hover:-translate-y-1 transition-all duration-300 hover:shadow-[#BD1B66]/10 cursor-pointer"
              onClick={() => setSelectedBlog(blog)}
            >
              <div className="flex flex-col md:flex-row">
                {/* Image Section */}
                <div className="md:w-2/5 relative">
                  <div className="h-64 md:h-full relative overflow-hidden">
                    <img
                      src={blog.image}
                      alt="Blog post"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute top-4 left-4">
                      <span className="bg-final text-white px-4 py-1 rounded-full text-sm font-medium">
                        {blog.category}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Content Section */}
                <div className="md:w-3/5 p-6 md:p-8">
                  <div className="flex items-center space-x-4 text-sm text-black/60 mb-4">
                    <div className="flex items-center">
                      <Calendar className="w-4 h-4 mr-1" />
                      {blog.date}
                    </div>
                    <div className="flex items-center">
                      <User className="w-4 h-4 mr-1" />
                      {blog.author}
                    </div>
                    <span>{blog.readTime}</span>
                  </div>

                  <h2 className="text-2xl font-bold text-black mb-4 hover:text-[#BD1B66] transition-colors duration-300">
                    {blog.title}
                  </h2>

                  <p className="text-black/70 mb-6 line-clamp-3">
                    {blog.excerpt}
                  </p>

                  <div className="flex items-center">
                    <button 
                      className="inline-flex items-center text-[#BD1B66] font-semibold hover:text-final transition-colors duration-300"
                    >
                      Read More 
                      <ArrowRight className="w-4 h-4 ml-2" />
                    </button>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>

      {/* Newsletter Section */}
      <div className="container mx-auto px-4 mt-20">
        <div className="max-w-4xl mx-auto bg-gradient-to-r from-[#BD1B66] to-final rounded-2xl p-8 md:p-12">
          <div className="text-center text-white">
            <h3 className="text-2xl md:text-3xl font-bold mb-4">
              Stay Updated with Investment Insights
            </h3>
            <p className="text-white/90 mb-8">
              Subscribe to our newsletter for regular updates on market trends and investment strategies
            </p>
            <div className="flex flex-col sm:flex-row gap-4 max-w-xl mx-auto">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-1 px-6 py-3 rounded-full text-black focus:outline-none focus:ring-2 focus:ring-white"
              />
              <button className="bg-white text-[#BD1B66] px-8 py-3 rounded-full font-semibold hover:bg-gray-100 transition-colors duration-300">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {selectedBlog && (
        <BlogModal 
          blog={selectedBlog} 
          onClose={() => setSelectedBlog(null)} 
        />
      )}
    </div>
  );
};

export default BlogListing;