// src/pages/Home.js
import React from 'react';
import HeroSection from '../components/heroSection6';
import PhilosophySection from "../components/PhilosophySection4";
import StepsSection from "../components/StepsSection4";

function Home() {
  return (
   <>
   <HeroSection />
   <PhilosophySection />
   {/* <div className="bg-[#F5F5F5] min-h-screen flex items-center justify-center"> */}
   <StepsSection />
   {/* </div> */}
   </>
  );
}

export default Home;
