import React, { useEffect, useRef } from 'react';
import { TrendingUp, Users, Award, Target, BookOpen, Brain } from 'lucide-react';
import { FiRefreshCw } from "react-icons/fi";
import { FaHandshake } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";

const AboutUs = () => {
  const statsRef = useRef(null);
  const contentRef = useRef(null);

  const stats = [
    { 
      icon: <Users className="w-8 h-8" />,
      label: "Unwavering Endurance",
      content: "A steadfast legacy of 50 years in the market."
    },
    { 
      icon: <BookOpen className="w-8 h-8" />, // Assuming BookOpen icon for learning and enlightenment
      label: "Expansive Enlightenment",
      content: "Continuously learning and imparting valuable insights."
    },
    { 
      icon: <FaHandshake className="w-8 h-8" />, // Assuming HandShake icon for guidance and support
      label: "Empowering Guidance",
      content: "Actively supporting clients toward financial independence."
    },
    { 
      icon: <FaHeart className="w-8 h-8" />, // Assuming Heart icon for trust and esteem
      label: "Enduring Esteem",
      content: "Cultivating deep trust and respect in every relationship."
    }
  ];
  

  const coreValues = [
    {
      icon: <BookOpen className="w-6 h-6" />,
      title: "Learn",
      description: "Building strong foundations in fundamental analysis"
    },
    {
      icon: <FiRefreshCw className="w-6 h-6" />,
      title: "Unlearn",
      description: "Adapting to changing market dynamics"
    },
    {
      icon: <Brain className="w-6 h-6" />,
      title: "Relearn",
      description: "Evolving strategies for modern markets"
    }
  ];

  const TimeLine = [
    { 
      year: "1977", 
      title: "The Foundation", 
      content: "Our story began with Shri Dilip Bhatt, a visionary banker whose career spanned Legal & Compliance and Loans & Advances Against Shares & Securities. His journey into the stock market began in 1977, viewing it as a means to educate and empower others."
    },
    { 
      year: "1996", 
      title: "The Next Generation", 
      content: "Rushik Bhatt embraced his father's legacy, learning the essence of market movements by recording weekly stock prices. This hands-on experience fostered a deep respect for the market as a lifelong commitment rather than a mere investment vehicle."
    },
    { 
      year: "2001", 
      title: "Inception of Avadhoot", 
      content: "In 2001, facing the challenges of a banking crisis, Shri Dilip Bhatt turned his lifelong knowledge of investments into a profession, assisting friends and family in managing their investments."
    },
    { 
      year: "2005", 
      title: "The Evolution", 
      content: "Thus, 'Avadhoot' was born, now known as Avadhoot Consultancy Services (ACS). Today, ACS stands as a trusted consultancy with roots in decades of financial wisdom and a dedication to empowering investors. Through our association with Shah Investor’s Home Ltd. (SIHL), established in 2005, ACS serves as an intermediary for executing trades on Indian exchanges."
    },
    { 
      year: "2024", 
      title: "Our Commitment to the Future", 
      content: "At ACS, we are committed to a continuous journey of learning, unlearning, and relearning. This commitment has led to the creation of Krishiv Venture Partners, having a dedicated initiative for equity education and execution under the name 'REequitiz' in 2024."
    },
    { 
      year: "Today", 
      title: "कल, आज और कल", 
      content: "From a pioneering first generation in 1977, through a millennial’s dedication in 1996, to the steps taken forward by Gen-Z, our journey represents nearly 50 years of shared insights and growth. We don’t see this as mere time passed; it’s a legacy of learning, resilience, and humble dedication to what we consider a mother market. Here, we serve as students, each day adding to our understanding and sharing that knowledge for a future of empowered, thoughtful investors."
    }
  ];
  

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-fadeIn');
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements = document.querySelectorAll('.animate-on-scroll');
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-white mt-20">
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-final text-white py-24">
        <div className="container mx-auto px-4 relative">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-3xl md:text-5xl font-bold mb-6 animate-fadeIn">
              Three Generations,
            </h1>
            <h1  className="text-3xl md:text-5xl font-bold mb-6 animate-fadeIn">
            <span className="text-white opacity-90"> One Mission</span>
            </h1>
            <p className="text-lg md:text-xl opacity-90 animate-fadeIn animation-delay-200">
            Equity Investment Driven by Endurance, Enlightenment, Empowerment, and Esteem.
            </p>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="container mx-auto px-4 -mt-16 relative z-10" ref={statsRef}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {stats.map((stat, index) => (
            <div 
              key={index}
              className="bg-white rounded-xl shadow-xl p-6 transform hover:-translate-y-2 transition-all duration-300 animate-on-scroll hover:shadow-[#BD1B66]/20"
              style={{ animationDelay: `${index * 200}ms` }}
            >
              <div className="flex items-center justify-center mb-4">
                <div className="p-3 bg-[#BD1B66]/10 rounded-full text-[#BD1B66]">
                  {stat.icon}
                </div>
              </div>
              <h3 className="text-2xl font-bold text-center text-black">{stat.label}</h3>
              <p className="text-black/70 text-center">{stat.content}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Our Story Section */}
      <div className="container mx-auto px-4 py-20">
        <div className="max-w-4xl mx-auto text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-[#BD1B66]">Our Journey</h2>
          <p className="text-xl text-black/70">A legacy of financial wisdom spanning three generations</p>
        </div>

        {/* Enhanced Responsive Timeline */}
        <div className="max-w-5xl mx-auto relative" ref={contentRef}>
          {/* Vertical Line - Hidden on mobile */}
          <div className="absolute left-8 md:left-1/2 transform md:-translate-x-1/2 h-full w-1 bg-gradient-to-b from-[#BD1B66]/20 via-[#BD1B66] to-[#BD1B66]/20 hidden md:block"></div>

          {/* Mobile Timeline Line */}
          <div className="absolute left-8 h-full w-1 bg-gradient-to-b from-[#BD1B66]/20 via-[#BD1B66] to-[#BD1B66]/20 md:hidden"></div>

          <div className="space-y-8 md:space-y-16">
            {TimeLine.map((item, index) => (
              <div 
                key={index} 
                className={`relative animate-on-scroll flex flex-col md:flex-row
                  ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'}
                  pl-16 md:pl-0 // Added left padding for mobile dot alignment
                `}
              >
                {/* Timeline Dot */}
                <div className={`
                  absolute left-8 md:left-1/2 transform -translate-x-1/2 
                  ${index % 2 === 0 ? 'md:-translate-x-1/2' : 'md:-translate-x-1/2'}
                  top-0 md:top-1/2 md:-translate-y-1/2
                  w-6 h-6 rounded-full border-4 border-[#BD1B66] bg-white shadow-lg z-10
                `}></div>
                
                {/* Content Container */}
                <div className={`
                  w-full md:w-5/12 
                  ${index % 2 === 0 ? 'md:pr-12' : 'md:pl-12'}
                `}>
                  <div className="bg-white rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300 overflow-hidden group">
                    {/* Year Banner */}
                    <div className="bg-gradient-to-r from-[#BD1B66] to-[#BD1B66]/80 p-4 transform group-hover:scale-105 transition-transform duration-300">
                      <div className="flex items-center justify-between">
                        <div>
                          <h3 className="text-2xl md:text-3xl font-bold text-white">{item.year}</h3>
                          <p className="text-white/90 font-medium">{item.title}</p>
                        </div>
                      </div>
                    </div>
                    
                    {/* Content */}
                    <div className="p-4 md:p-6 bg-gradient-to-b from-white to-[#BD1B66]/5">
                      <p className="text-base md:text-lg text-black/80 leading-relaxed">{item.content}</p>
                    </div>
                  </div>
                </div>
                
                {/* Empty space for the other side - Hidden on mobile */}
                <div className="hidden md:block md:w-5/12"></div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Core Values Section */}
      <div className="bg-white py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#BD1B66]">Our Core Values</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {coreValues.map((value, index) => (
                <div key={index} className="bg-white p-6 rounded-xl shadow-lg animate-on-scroll hover:shadow-[#BD1B66]/20 transition-all duration-300">
                  <div className="flex items-center justify-center mb-4">
                    <div className="p-3 bg-[#BD1B66]/10 rounded-full text-[#BD1B66]">
                      {value.icon}
                    </div>
                  </div>
                  <h3 className="text-xl font-bold text-center mb-2 text-black">{value.title}</h3>
                  <p className="text-black/70 text-center">{value.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="bg-[#BD1B66] text-white py-16">
        <div className="container mx-auto px-4 text-center animate-on-scroll">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Begin Your Investment Journey</h2>
          <p className="text-xl mb-8 opacity-90">Join us in building a future of thoughtful, empowered investors</p>
          <a
            href="/contact"
            className="inline-block bg-white text-black font-bold px-8 py-4 rounded-full shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300"
          >
            Start Learning Today
          </a>
        </div>
      </div>

      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fadeIn {
          animation: fadeIn 1s ease-out forwards;
        }

        .animation-delay-200 {
          animation-delay: 200ms;
        }

        .animate-on-scroll {
          opacity: 0;
          transform: translateY(20px);
          transition: all 0.6s ease-out;
        }

        .animate-on-scroll.animate-fadeIn {
          opacity: 1;
          transform: translateY(0);
        }

        @media (max-width: 768px) {
          .animate-on-scroll {
            transform: translateX(0);
          }
        }
      `}</style>
    </div>
  );
};

export default AboutUs;