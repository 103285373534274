// // src/components/Footer.js
// import React from 'react';

// function Footer() {
//   return (
//     <footer className="bg-primary text-white py-6 mt-4">
//       <div className="container mx-auto text-center text-sm px-4">
//         <p>&copy; 2024 REequitiz. All Rights Reserved.</p>
//         <p>Empowering Indian Investors with Purpose-Driven Investing.</p>
//       </div>
//     </footer>
//   );
// }

// export default Footer;

// src/components/Footer.js
import React from 'react';
import { Facebook, Twitter, LinkedIn } from 'lucide-react';
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import LOGO from "../assets/logo1.png";

const Footer = () => {
  return (
    <footer className="bg-final text-white py-10">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          {/* Logo and description */}
          <div className="flex items-center space-x-3 mb-6 md:mb-0">
            <img src={LOGO} alt="Logo" className="h-28 w-auto border-none" />
            {/* <p className="text-sm max-w-xs">
              REequitiz empowers Indian retail investors with a holistic, educational approach to make strategic financial decisions.
            </p> */}
          </div>
          
          {/* Quick Links */}
          <div className="flex space-x-8">
            <a href="/" className="hover:underline text-sm">Home</a>
            <a href="/about" className="hover:underline text-sm">About Us</a>
            <a href="/blogs" className="hover:underline text-sm">Blogs</a>
            <a href="/disclaimer" className="hover:underline text-sm">Disclaimer</a>
            <a href="/contact" className="hover:underline text-sm">Contact Us</a>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center border-t border-white/10 pt-6">
          {/* Contact Information */}
          <div className="text-center md:text-left mb-6 md:mb-0">
            <p className="text-sm">Email: support@reequitiz.in</p>
            {/* <p className="text-sm">Phone: +91-97240-36036</p> */}
            <p className="text-sm">Address: B/410, Ganesh Plaza, Nr. Navrangpura Post Office, Navrangpura, Ahmedabad, Gujarat, India - 380 009</p>
          </div>

          {/* Social Media Icons */}
          <div className="flex space-x-4">
            <a href="https://facebook.com" className="text-white hover:text-[#CE0058] transition">
              <Facebook className="w-6 h-6" />
            </a>
            <a href="https://twitter.com" className="text-white hover:text-[#CE0058] transition">
              <Twitter className="w-6 h-6" />
            </a>
            <a href="https://instagram.com" className="text-white hover:text-[#CE0058] transition">
              <FaInstagram className="w-6 h-6" />
            </a>
            <a href="https://linkedin.com" className="text-white hover:text-[#CE0058] transition">
              <FaLinkedin className="w-6 h-6" />
            </a>
          </div>
        </div>

        {/* Copyright */}
        <div className="text-center mt-6 text-sm text-white/70">
          © {new Date().getFullYear()} REequitiz. All rights reserved.
          <br />
          Powered By Hexylon Analytics
        </div>
      </div>
    </footer>
  );
};

export default Footer;
